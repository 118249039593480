import Vue from 'vue/dist/vue.esm';
import VueRouter from 'vue-router';
import store from '../store';

Vue.use(VueRouter);

export default function routerBeforeEachSetup(router) {
  router.beforeEach((to, from, next) => {
    // Startpage always reachable
    if (to.path == '/') {
      return next();
    }

    store.commit('auth/update');
    if (store.getters['auth/checkAuth'] == false) {
      // Send back to start
      if (to.name === 'ZxLogout') {
        return next({ name: 'ZxHome' });
      }

      // Permanent Allow
      var allow = ['ZxLogin', 'ZxRegister'];
      if (allow.indexOf(to.name) === -1) {
        return next({
          name: 'ZxLogin',
          params: { next: to.path },
        });
      }
    }

    // Send authenticated user back to index
    if (to.name === 'ZxLogin' && store.getters['auth/checkAuth']) {
      return next({ name: 'ZxHome' });
    }

    // Page requires permissions
    if (to.meta.requiresPerm != null) {
      if (store.getters['auth/checkAuth']) {
        var data = store.getters['setting/getData'];
        var perms = data.perm;
        if (perms == null) {
          return next({
            name: 'ZxSetup',
            params: { next: to.path },
          });
        } else if (perms.includes(to.meta.requiresPerm)) {
          return next();
          
        } else {
          return next({ name: 'ZxForbidden' });
        }
      } else {
        return next({
          name: 'ZxLogin',
          params: { next: to.path },
        });
      }
    } else {
      return next();
    }
  });
}
