/**
 *    ACHTUNG
 * 
 *    Diese Datei wurde generiert
 *    Bitte nur das Template bearbeiten.
 *    dann via `rails generate zx:install` Updaten
 */

import Vue from 'vue/dist/vue.esm';
import VueRouter from 'vue-router';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

import store from 'store';
import httpSetup from 'rahmen/http.js';
import routerBeforeEachSetup from 'rahmen/router.js';

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueRouter);

// Use VueRessource and setup interceptor
httpSetup();

import zxFielderror from 'components/rahmen/zxfielderror.vue';
Vue.component('zx-fielderror', zxFielderror);

// Views Basic
import zxRahmenLogin from 'views/share/login.vue';
import zxRahmenLogout from 'views/share/logout.vue';
import Zx403Page from 'views/share/403.vue';
// Views Rahmen
import zxRahmenRegister from 'views/share/register.vue';
import ZxStartPage from 'views/share/start.vue';

const zxRoutes = [
  { path: '/setup', redirect: { name: 'ZxHome' } },
  {
    path: '/register',
    name: 'ZxRegister',
    component: zxRahmenRegister,
  },
  {
    path: '/login',
    name: 'ZxLogin',
    component: zxRahmenLogin,
  },
  {
    path: '/logout',
    name: 'ZxLogout',
    component: zxRahmenLogout,
  },
  {
    path: '/403',
    name: 'ZxForbidden',
    component: Zx403Page,
  },
  {
    path: '/',
    name: 'ZxHome',
    component: ZxStartPage,
  },
];

const zxRouter = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: zxRoutes,
});

// Router-Setup
routerBeforeEachSetup(zxRouter);

const zxUser = new Vue({
  el: '#app',
  router: zxRouter,
  store: store,
});
