<script>
export default {
  data() {
    return {
      show: true,

    }
  },
  methods: {
    goBack(event) {
      event.preventDefault();
      this.$router.go(-1);
    }
  }
}
</script>

<template>
  <b-container>
    <b-row>
      <b-col lg="2">
      </b-col>
      <b-col lg="6" style="margin-top: 30%;">
        <h1>Forbidden</h1>
        <ul>
          <li>
            <a href="#" @click="goBack">go back</a>
          </li>
          <li>
            <a href="/">go to start</a>
          </li>
          <li>
            <a href="/logout">go to logout</a>
          </li>
        </ul>
      </b-col>
    </b-row>
  </b-container>
</template>
