<script>
export default {
  data() {
    return {
      show: true,
      value: 0,
      max: 100,
      data: {
        csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      },
      success: false,
      error: false,
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.value = this.value + 3;
      if (this.value >= 105) {
        this.logout();
      } else {
        setTimeout(
          function () {
            this.load();
          }.bind(this),
          50,
        );
      }
    },
    logout() {
      var data = {
        authenticity_token: this.data.csrf,
      };
      this.$http.delete('/auth/sign_out', { body: data }).then(
        (res) => {
          if (res.body) {
            this.$store.commit('auth/clean');
            if (res.body.success == true) {
              this.success = true;
              setTimeout(
                function () {
                  //this.$router.push('/');
                  location.href = '/';
                }.bind(this),
                1000,
              );
            } else {
              this.error = true;
            }
          } else {
            this.error = true;
          }
        },
        (res) => {
          if (res.body) {
            if (res.body.success != null && res.body.success == false) {
              this.errors = res.body.errors;
            }
          } else {
            alert('Error.');
          }
        },
      );
    },
  },
};
</script>

<template>
  <b-container>
    <b-row>
      <b-col lg="2"> </b-col>
      <b-col lg="6" style="margin-top: 30%">
        <h1>Tschüß</h1>
        <b-progress :value="value" :max="max" animated></b-progress>
        <b-alert v-model="error" variant="danger"> Logout Fail </b-alert>
        <b-alert v-model="success" variant="success"> Logout Success </b-alert>
        <router-link to="/"> zum Start</router-link>
      </b-col>
    </b-row>
  </b-container>
</template>
