<script>
import {
  RubyIcon,
  JavaScriptIcon,
  VueJsIcon,
  BootstrapIcon,
  DockerIcon,
  LinuxIcon,
  LaTeXIcon,
  GitLabIcon,
} from 'vue-simple-icons';
export default {
  components: {
    RubyIcon,
    LinuxIcon,
    VueJsIcon,
    JavaScriptIcon,
    BootstrapIcon,
    DockerIcon,
    LaTeXIcon,
    GitLabIcon,
  },
  data() {
    return {
      show: true,
      csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      page: [],
      apps: [],
      timer: null,
      counter: 10,
    };
  },
  methods: {
    link(link) {
      location.href = link;
    },
    step_timer() {
      var num = this.counter;
      var el = 'bl' + num;
      var obj = document.getElementById(el);
      var move = 'animate__bounceIn';
      if (num < 5) move = 'animate__rotateIn';
      if (num < 3) move = 'animate__fadeInTopRight';
      if (obj) {
        obj.classList.add(move);
      }
      if (num == 1) {
        clearInterval(this.timer);
      }
    },
    start_timer() {
      this.timer = setInterval(() => {
        this.counter = this.counter - 1;
        this.step_timer();
      }, 300);
    },
  },
  mounted() {
    var data = { page: 'start', authenticity_token: this.csrf };
    this.$http.post('/page', data).then((res) => {
      if (res.body) {
        if (res.body.page) {
          this.page = res.body.page;
        }
      } else {
        this.error = true;
      }
    });
    setTimeout(
      function () {
        this.start_timer();
      }.bind(this),
      700,
    );
  },
};
</script>

<template>
  <div>
    <b-container>
      <b-row>
        <b-col class="page-col" lg="6">
          <h1 class="mb-5 mt-5">{{ page.title }}</h1>
          <b-alert show variant="success" class="mb-5" v-if="$route.query.confirm == 'true'">
            Abschluss Registration erfolgreich. Sie können sich jetzt anmelden.
          </b-alert>
          <b-alert show variant="danger" class="mb-5" v-if="$route.query.confirm == 'false'">
            Abschluss der Registration fehlgeschlagen. Wurde das Konto bereits aktiviert ?
          </b-alert>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          class="app-col"
          cols="12"
          md="6"
          lg="3"
          v-for="(page, index) in page.apps"
          v-bind:key="index"
        >
          <b-card
            :title="page.name"
            :img-src="page.image"
            img-alt=""
            img-top
            tag="article"
            @click="link(page.url)"
            class="zoom zoom11"
          >
            <b-card-text>
              {{ page.description }}
            </b-card-text>
            <b-button block variant="primary">
              <b-icon icon="folder2-open" aria-hidden="true"></b-icon>
              Start
            </b-button>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
    <footer class="footer">
      <b-container>
        <b-row>
          <b-col lg="2" md="2" sm="5" class="mt-2 mb-2">
            <div class="hex2-container">
              <div class="hex2-row">
                <div
                  class="hex2 first animate__animated red animate__slower"
                  style="opacity: 0"
                  id="bl3"
                >
                  <div class="left"></div>
                  <div class="middle"></div>
                  <div class="right"></div>
                </div>
                <div
                  class="hex3 animate__animated animate__delay-2s red"
                  style="opacity: 0"
                  id="bl2"
                >
                  <div class="left"></div>
                  <div class="middle"></div>
                  <div class="right"></div>
                </div>
                <div
                  class="hex4 animate__animated animate__delay-2s red"
                  style="opacity: 0"
                  id="bl1"
                >
                  <div class="left"></div>
                  <div class="middle"></div>
                  <div class="right"></div>
                </div>
              </div>
              <div class="hex2-row">
                <div
                  class="hex2 animate__animated animate__slower blue"
                  style="opacity: 0"
                  id="bl9"
                >
                  <div class="left"></div>
                  <div class="middle"></div>
                  <div class="right"></div>
                </div>
                <div
                  class="hex2 even animate__animated red animate__slower"
                  style="opacity: 0"
                  id="bl4"
                >
                  <div class="left"></div>
                  <div class="middle"></div>
                  <div class="right"></div>
                </div>
                <div
                  class="hex2 animate__animated animate__slower blue"
                  style="opacity: 0"
                  id="bl8"
                >
                  <div class="left"></div>
                  <div class="middle"></div>
                  <div class="right"></div>
                </div>
              </div>
              <div class="hex2-row">
                <div
                  class="hex2 animate__animated animate__slower blue"
                  style="opacity: 0"
                  id="bl7"
                >
                  <div class="left"></div>
                  <div class="middle"></div>
                  <div class="right"></div>
                </div>
                <div
                  class="hex2 even animate__animated animate__slower blue"
                  style="opacity: 0"
                  id="bl5"
                >
                  <div class="left"></div>
                  <div class="middle"></div>
                  <div class="right"></div>
                </div>
                <div
                  class="hex2 animate__animated animate__slower blue"
                  style="opacity: 0"
                  id="bl6"
                >
                  <div class="left"></div>
                  <div class="middle"></div>
                  <div class="right"></div>
                </div>
              </div>
            </div>
          </b-col>
          <b-col lg="5" sm="10" class="mt-2">
            powered by
            <hr class="mt-1 mb-1" />
            <h3>
              <span class="move zg_blue_color">ZaGo</span><span class="move zg_red_color">Tec</span>
              <span class="move zg_blue_color">GmbH</span>
            </h3>
            <b-row>
              <b-col md="6">
                Tempowerkring 21d <br />
                21079 Hamburg
              </b-col>
              <b-col md="6">
                https://www.zagotec.de <br />
                mail@zagotec.de
              </b-col>
            </b-row>
          </b-col>
          <b-col lg="5" sm="12" class="mt-2">
            made with
            <hr class="mt-1 mb-1" />
            <b-row>
              <b-col cols="6">
                <linux-icon size="1.3x" class="mr-2" /> Open Source<br />
                <git-lab-icon size="1.3x" class="mr-2" /> GitLab <br />
                <docker-icon size="1.3x" class="mr-2" /> Docker <br />
                <la-te-x-icon size="1.3x" class="mr-2" /> Latex<br />
              </b-col>
              <b-col cols="6">
                <ruby-icon size="1.1x" class="mr-2" /> Ruby on Rails <br />
                <java-script-icon size="1.1x" class="mr-2" /> Javascript <br />
                <vue-js-icon size="1.3x" class="mr-2" /> Vue <br />
                <bootstrap-icon size="1.3x" class="mr-2" /> Bootstrap <br />
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </footer>
  </div>
</template>

<style lang="scss" scoped>
@import 'styles/_variables.scss';

.app-col {
  cursor: pointer;
  z-index: 1;
}

@media (min-width: map-get($grid-breakpoints, lg)) {
  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 200px; /* Set the fixed height of the footer here */
    z-index: 0;
  }
}
@media (max-width: map-get($grid-breakpoints, md)) {
  .app-col {
    margin-top: 30px;
  }
}
body {
  margin-bottom: 60px; /* Margin bottom by footer height */
}
.move:hover {
  display: inline-block;
  -webkit-animation: heartBeat 2s;
  animation: heartBeat 2s;
}

.footer {
  background-color: #f5f5f5;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-top: 10px;
}

.footer svg path {
  fill: $gray-800;
}
</style>
