<script>
export default {
  name: 'zxform',
  props: {
    app: {
      default: '',
    },
  },
  data() {
    return {
      show: true,
      data: {
        email: '',
        password: '',
        csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
      },
      page: [],
      errors: [],
    };
  },
  methods: {
    onSubmit(event) {
      event.preventDefault();
      this.errors = [];
      if (this.data.email.length < 3 || this.data.password.length < 3) {
        this.errors.push('Invalid login credentials. Please try again.');
        return false;
      }
      var data = {
        email: this.data.email,
        password: this.data.password,
        authenticity_token: this.data.csrf,
      };
      this.$http.post('/auth/sign_in', data).then(
        (res) => {
          if (res.body) {
            if (res.body.data && res.body.data.id && res.headers.get('access-token') != null) {
              this.login(res);
            }
          } else {
            this.error = true;
          }
        },
        (res) => {
          if (res.body) {
            if (res.body.success != null && res.body.success == false) {
              this.errors = res.body.errors;
            }
          } else {
            alert('Error.');
          }
        },
      );
    },
    login(res) {
      var auth = {
        token: res.headers.get('access-token'),
        client: res.headers.get('client'),
        expiry: res.headers.get('expiry'),
        uid: res.body.data.uid,
      };
      // set Auth to CookieStore (auth)
      this.$store.commit('auth/setData', { 'auth': auth});
      // set Settings to OnlyStore (setting)
      this.$store.commit('setting/setData', res.body.setting);

      // Weiterleitung via $route.params.next
      if (this.$route.params.next) {
        this.$router.push({ path: this.$route.params.next });
        return;
      }
      // Weiterleitung im Result
      if (res.body.redirect != null) {
        this.$router.push({ path: res.body.redirect });
        return;
      }
      // Keine Weiterleitung bekommen - gehe zu Home
      if (this.$router.resolve('ZxHome')) {
        this.$router.push({ name: 'ZxHome' });
      } else {
        window.location.href = '/';
      }
    },
  },
  mounted() {
    var data = {
      page: 'login',
      app: this.app,
      authenticity_token: this.data.csrf,
    };
    this.$http.post('/page', data).then((res) => {
      if (res.body) {
        if (res.body.page) this.page = res.body.page;
      } else {
        this.error = true;
      }
    });
  },
};
</script>

<template>
  <b-form @submit="onSubmit" v-if="show">
    <b-container>
      <b-row>
        <b-col lg="6" style="margin-top: 10%">
          <b-row>
            <b-col lg="12">
              <b-form-group label-cols-md="3" label=" ">
                <h1 v-if="!page.app || !page.app.name">{{ page.title }}</h1>
              </b-form-group>
            </b-col>
            <b-col lg="12">
              <b-form-group id="group-usernames" label-cols-md="3" label="" label-for="">
                <b-card
                  v-if="page.app && page.app.name"
                  :title="page.app.name"
                  :img-src="page.app.image"
                  img-alt=""
                  img-top
                  tag="article"
                  class="mb-2"
                >
                  <b-card-text>
                    {{ page.app.description }}
                  </b-card-text>
                </b-card>
              </b-form-group>
            </b-col>
            <b-col lg="12">
              <b-form-group id="group-email" label-cols-md="3" label="Email" label-for="email">
                <b-form-input id="email" v-model="data.email" autofocus></b-form-input>
              </b-form-group>
            </b-col>
            <b-col lg="12">
              <b-form-group
                id="group-password"
                label-cols-md="3"
                label="Password"
                label-for="password"
              >
                <b-form-input
                  id="password"
                  v-model="data.password"
                  type="password"
                  @keydown.enter="onSubmit"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col lg="12">
              <b-form-group id="group-sub" label-cols-md="3" label=" " label-for="sub">
                <b-button id="sub" type="button" @click="onSubmit" variant="primary"
                  >Login</b-button
                >
              </b-form-group>
            </b-col>
            <b-col lg="12">
              <b-form-group id="group-msg" label-cols-md="5" label-cols-lg="3" label=" ">
                <b-alert show variant="danger" v-for="(item, index) in errors" v-bind:key="index">
                  {{ item }}
                </b-alert>
              </b-form-group>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </b-form>
</template>
