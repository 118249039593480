<script>
export default {
  name: "ZxRahmenRegister",
  methods: {
    // call for "Register" button click
    onSubmit(event) {
      event.preventDefault();

      let data = {
        user: this.data,
        authenticity_token: this.csrfToken,
      };

      this.$http.post("/auth", data).then(
        (response) => {
          if (response.body["ok"]) {
            alert("User was registered. TODO");
            this.$router.push("/");
          }
          if (response.body.fielderror) {
            this.fielderror = response.body.fielderror;
          } else {
            this.fielderror = [];
          }
        },
        (error) => {
          console.log("Failure");
        }
      );
    },
    /**
     * Checks if the confirmation password is equal to password
     */
    passwordConfirmationState() {
      if (this.data.password_confirmation.length == 0) {
        return null;
      }
      if (this.data.password != this.data.password_confirmation) {
        return false;
      }
      return true;
    },
    /**
     * Returns the state of a field with name 'fieldName'.
     * Kopie aus zxForm
     */
    checkfielderror: function (name) {
      var ret = null;
      var msg = this.fielderror[name];
      if (msg) {
        ret = msg.length > 0 ? false : null;
      }
      return ret;
    },
  },
  data() {
    return {
      show: true,
      data: {
        email: "",
        username: "",
        password: "",
        password_confirmation: "",
      },
      csrfToken: document
        .querySelector('meta[name="csrf-token"]')
        .getAttribute("content"),
      fielderror: {},
    };
  },
};
</script>

<template>
  <b-form @submit="onSubmit" v-if="show">
    <b-container>
      <!--HEAD-->
      <b-row>
        <b-col lg="2"> </b-col>
        <b-col lg="6" style="margin-top: 20%">
          <b-form-group
            label-cols-sm="5"
            label-cols-lg="3"
            label=" "
            label-for="sub"
          >
            <h1>Register</h1>
          </b-form-group>
        </b-col>
      </b-row>

      <!--Username-->
      <b-row>
        <b-col lg="2"> </b-col>
        <b-col lg="6">
          <b-form-group
            id="group-username"
            label-cols-sm="5"
            label-cols-lg="3"
            label="Username"
            label-for="username"
          >
            <b-form-input
              id="username"
              v-model="data.username"
              :state="checkfielderror('username')"
            ></b-form-input>
            <zx-fielderror :value="fielderror.username"></zx-fielderror>
          </b-form-group>
        </b-col>
      </b-row>

      <!--Email-->
      <b-row>
        <b-col lg="2"> </b-col>
        <b-col lg="6">
          <b-form-group
            id="group-username"
            label-cols-sm="5"
            label-cols-lg="3"
            label="Email"
            label-for="email"
          >
            <b-form-input
              id="email"
              v-model="data.email"
              :state="checkfielderror('email')"
            ></b-form-input>
            <zx-fielderror :value="fielderror.email"></zx-fielderror>
          </b-form-group>
        </b-col>
      </b-row>

      <!--Password-->
      <b-row>
        <b-col lg="2"> </b-col>
        <b-col lg="6">
          <b-form-group
            id="group-password"
            label-cols-sm="5"
            label-cols-lg="3"
            label="Password"
            label-for="password"
          >
            <b-form-input
              id="password"
              v-model="data.password"
              type="password"
              :state="checkfielderror('password')"
            >
            </b-form-input>
            <zx-fielderror :value="fielderror.password"></zx-fielderror>
          </b-form-group>
        </b-col>
      </b-row>

      <!--Password confirmation-->
      <b-row>
        <b-col lg="2"> </b-col>
        <b-col lg="6">
          <b-form-group
            id="group-password"
            label-cols-sm="5"
            label-cols-lg="3"
            label="Confirm Password"
            label-for="confirmPassword"
          >
            <b-form-input
              id="confirmPassword"
              v-model="data.password_confirmation"
              type="password"
              :state="passwordConfirmationState()"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col lg="2"> </b-col>
        <b-col lg="6">
          <b-form-group
            id="group-sub"
            label-cols-sm="5"
            label-cols-lg="3"
            label=" "
            label-for="sub"
          >
            <b-button id="sub" type="submit" variant="primary"
              >Register</b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-container>
  </b-form>
</template>

