import Vue from 'vue/dist/vue.esm';
import VueResource from 'vue-resource';
import store from '../store';

Vue.use(VueResource);

export default function http() {
  Vue.http.interceptors.push(function (request, next) {
    store.commit('auth/update');
    var data = store.getters['auth/getData'];
    var auth = null;
    if (store.getters['auth/checkAuth']) {
      auth = data.auth;
      request.headers.set('access-token', auth.token);
      request.headers.set('client', auth.client);
      request.headers.set('uid', auth.uid);
    }

    next(function (response) {
      var auth = {
        token: response.headers.get('access-token'),
        client: response.headers.get('client'),
        expiry: response.headers.get('expiry'),
        uid: response.headers.get('uid'),
      };

      if (auth && auth.token) {
        data.auth = auth;
        store.commit('auth/setData', data);
      }

      /**
       * Beim Login nicht weiterleiten
       */
      if (this.$router.currentRoute.fullPath == '/login') {
        return;
      }

      if (response.status == 404) {
        this.$router.go({ name: 'ZxNotFound' });
        return;
      }

      if (response.status == 401) {
        this.$store.commit('auth/clean');
        this.$router.go({ name: 'ZxLogin' });
        return;
      }
      if (response.status != 200) {
        var body = response.body;
        if (response.body !== undefined && response.body.message) body = response.body.message;
        var options = {
          id: 'zx-page-toast',
          title: response.status,
          body: body,
          variant: 'danger',
          solid: true,
          toaster: 'b-toaster-top-full',
          autoHideDelay: 5000,
          href: process.env.NODE_ENV !== 'production' ? '/__better_errors' : null,
          noCloseButton: false,
        };
        if (response.status == 405) {
          var add = {
            variant: 'warning',
            toaster: 'b-toaster-bottom-right',
            noCloseButton: true,
            href: null,
          };
          options = Object.assign(options, add);
        }
        this.$bvToast.toast(body, options);
        this.alert = [];
      }
    });
  });
}
